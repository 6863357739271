<script>
export default {
    props: {
        actionHistoryItemId: {
            type: Number,
            required: true,
        },
        hstActionSyncStatuses: {
          type: Object,
          required: true
        }
    }
}
</script>

<template>
  <div v-if="typeof hstActionSyncStatuses.errors[actionHistoryItemId] !== 'undefined'">
    <div class="mt-2">
      <p class="text-red-600 font-semibold">HST Sync Error: <em>{{ hstActionSyncStatuses.errors[actionHistoryItemId] }}</em></p>
    </div>
  </div>
  <div v-else-if="typeof hstActionSyncStatuses.ok[actionHistoryItemId] !== 'undefined'">
    <div class="mt-2">
      <p class="text-green-600 font-semibold">Synced to HST</p>
    </div>
  </div>
</template>